import React from 'react';
import { ReactComponent as QuestioningSloth } from '../assets/questioning-sloth.svg';
import { APP_NOT_FOUND_TITLE } from '../constants';
// eslint-disable-next-line max-lines-per-function
const AppNotFound = () => {
  return (
    <div
      className="flex flex-col justify-middle gap-[30px] bg-system-gray-7
      py-12 items-center justify-center mx-auto h-screen
      dark:bg-dark-primary dark:text-dark-label-primary"
    >
      <QuestioningSloth />
      <span
        id={APP_NOT_FOUND_TITLE}
        className={'flex text-xl text-label-primary font-medium dark:text-dark-label-primary'}
      >
        You can't view this app at the moment!
      </span>
      <div
        className="flex gap-3 flex-col text-sm text-label-light-grey
      font-medium justify-center items-center"
      >
        <span className="flex">
          If you are the owner of this app, go to &nbsp;
          <a href="/" className="text-sm font-medium text-system-blue-6 cursor-pointer">
            your apps
          </a>
        </span>
      </div>
    </div>
  );
};

export default AppNotFound;
